import React, { forwardRef } from "react";
import "./About.css";
import imageTwo from "../../assets/Trvia-Night-1080p-color-0024.jpg"
import imageThree from "../../assets/Trvia-Night-1080p-color-0006.jpg"
const About = forwardRef((props, ref) => {
  return (
      <div ref={ref} className="about">

        <div className="body-text-container">
          <h1>Brain Brew Trivia</h1>
          <div>
          <span>
          Brain Brew Trivia is bringing a fresh take on pub trivia to the Grand Valley! We&apos;re creating engaging quiz nights for party-goers, mountain bikers, college students, Kiwanis club members, brew enthusiasts, couples, and anyone lucky enough to stop by our valley. As the newest trivia company in town, we&apos;re building an exciting collection of original questions and fostering a vibrant trivia community that brings fun and friendly competition to local venues.
          </span>
          </div>
          <div>
            <img className="imageOne mobile-image" src={imageTwo} />
            <span>
            Our goal is to deliver well-rounded trivia that goes beyond simply reading off factoids with question marks. Not everyone is a history buff, a movie expert, or a literature fanatic — and that&apos;s okay! Our quizzes are designed to be inclusive, challenging, and engaging for all types of knowledge enthusiasts.
          </span>
            <img className="imageOne desktop-image" src={imageTwo} />

          </div>
          <div>
            <img className="imageOne" src={imageThree} />
            <span>
            Visit us at any of our growing list of venues, where you&apos;ll find a consistent welcoming format but a fresh quiz every time. Whether you&apos;re a seasoned quiz player or a first-timer, Brain Brew Trivia creates an exciting, friendly atmosphere where you can test your wits and enjoy the night. Come join our trivia community as we build something special in the Grand Valley!
          </span>
          </div>
        </div>
      </div>
  );
});

About.displayName = "About";

export default About;