import React, { useState, useEffect } from "react";
import {toast} from "react-toastify";
import styles from "./checkscores.css";
import Modal from "../Modals/Modal/modal";

const CheckScores = () => {
    const [formData, setFormData] = useState({ pin: "" });
    const [pinState, setPinState] = useState(false);
    const [pinData, setPinData] = useState([]);
    const [bestSmallTeam, setBestSmallTeam] = useState(null);
    const [pageTitle, setPageTitle] = useState("Trivia Game");
    const [teamsHaveDoubled, setTeamsHaveDoubled] = useState(false);

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const pin = urlParams.get('pin');
        if (pin) {
            setFormData({ pin });
            handleSubmit(null, pin);
        }
    }, []);

    const handleSubmit = async (e, pin) => {
        if (e) e.preventDefault();

        const pinToUse = pin || formData.pin;
        if (!pinToUse) {
            toast.error('Pin is required', { toastId: 'error1' });
            return;
        }

        const apiUrl = `${process.env.REACT_APP_API_URL}/games/get_all_teams?pin=${encodeURIComponent(pinToUse)}`;

        try {
            const response = await fetch(apiUrl, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            if (!response.ok) {
                toast.error('Error with Pin', { toastId: 'error1' });
                return;
            }

            const data = await response.json();
            
            // Validate required data exists
            if (!data || !data.game || !data.teams) {
                toast.error('Invalid data received from server', { toastId: 'error2' });
                return;
            }

            // Safely get the date
            const today = new Date(data.game.createdAt || Date.now());
            const month = today.getMonth() + 1;
            const day = today.getDate();
	    console.log(data)
            setPageTitle(`${data.game.venue.name || 'Trivia Game'} ${month}/${day}`);

            // Transform teams data with null checks
            let teams = Object.entries(data.teams || {}).map(([key, value]) => ({
                key,
                name: value?.name || 'Unknown Team',
                playerCount: value?.player_count || 0,
                score: value?.score || 0,
                roundScores: value?.roundScores || {},
            }));

            // Sort teams by score in descending order
            teams.sort((a, b) => (b.score || 0) - (a.score || 0));

            let currentPlace = 1;
            let previousScore = null;

            // Assign places to teams
            teams = teams.map((team, index) => {
                if (team.score !== previousScore) {
                    currentPlace = index + 1;
                }
                previousScore = team.score;
                return { ...team, place: currentPlace };
            });

            // Mark teams that have a doubled round
            teams.forEach(team => {
                team.doubled = Object.values(team.roundScores || {}).some(score => score?.double);
            });
            setTeamsHaveDoubled(teams.some(team => team.doubled));


            // Filter out teams with more than 6 players and mark them
            let eligibleTeams = teams.filter(team => (team.playerCount || 0) <= 6);

            // Sort eligible teams by score in descending order
            eligibleTeams.sort((a, b) => (b.score || 0) - (a.score || 0));

            // Only process if we have eligible teams
            if (eligibleTeams.length > 0) {
                // Mark top teams (first and second place)
                const firstPlaceTeams = eligibleTeams.filter(team => 
                    team.score === eligibleTeams[0].score && team.score > 0
                );

                if (eligibleTeams.length > firstPlaceTeams.length) {
                    const secondPlaceTeams = eligibleTeams.filter(
                        team => team.score === eligibleTeams[firstPlaceTeams.length].score && team.score > 0
                    );
                }

                // Find best small teams (less than 4 players)
                let bestSmallTeamScore = 0;
                eligibleTeams.forEach(team => {
                    if ((team.playerCount || 0) < 4 && 
                        (team.playerCount || 0) > 0 && 
                        (team.score || 0) >= bestSmallTeamScore && 
                        (team.score || 0) > 0 && 
                        team.icon === undefined) {
                        if (bestSmallTeamScore === 0) {
                            setBestSmallTeam(team);
                            bestSmallTeamScore = team.score;
                        }
                    }
                });
            }

            setPinState(true);
            setPinData(teams);
            toast.success('Pin Found', { toastId: 'success1' });
            window.history.pushState(null, '', `?pin=${encodeURIComponent(pinToUse)}`);

        } catch (error) {
            console.error('Error finding pin:', error);
            toast.error('Error finding pin. Please try again.', { toastId: 'error3' });
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevData => ({ ...prevData, [name]: value.trim() }));
    };

    const largeTeams = (pinData || []).filter((team) => (team.playerCount || 0) > 6).length > 0;
    const feedbackFormUrl = "https://docs.google.com/forms/d/e/1FAIpQLSc5nar5JfbuCdZ23fV8rI3HWLqtfKQYDrd8kfWqmDmpXCYD0A/viewform?usp=sf_link";

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalType, setModalType] = useState('howToPlay');

    const openModal = (type) => {
        setModalType(type);
        setIsModalOpen(true);
    };

    const closeModal = () => setIsModalOpen(false);

    return (
        <div className="scoresheet">
            {pinState ? (
                <div>
                    <div className="headerContainer">
                        <span className="headerTitle">{pageTitle}</span>
                        <span><a href={feedbackFormUrl} rel="noreferrer" target="_blank">Give feedback</a></span>
                    </div>
                    <div className="table-container">
                        <table>
                            <thead>
                                <tr>
                                    <th>Place</th>
                                    <th>Name</th>
                                    <th>Score</th>
                                </tr>
                            </thead>
                            <tbody>
                                {pinData.map((team) => (
                                    <tr key={team.key} className={styles.trStyle}>
                                        <td className={styles.tdStyle}>{team.place}</td>
                                        <td className={styles.tdStyle}>
                                            {team.icon} {team.name} {team.doubled ? "⚡" : ""}
                                        </td>
                                        <td className={styles.tdStyle}>{team.score}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                        <div style={{display: "flex", justifyContent: "center"}}>
                            <div style={{display: "flex", flexDirection: "column", justifyContent: "center", marginTop: "20px"}}>
                                {teamsHaveDoubled && <div>⚡ = Doubled Round</div>}
                                <div className="helpInfo">
                                    <button onClick={() => openModal('howToPlay')}>
                                        <span>How to Play</span>
                                    </button>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            ) : (
                <div className="pinInput" style={{width: "70vw", margin: "auto"}}>
                    <form onSubmit={handleSubmit}>
                        <div className="mb-3">
                            <label htmlFor="pin" className="inputLabel">Quiz Pin</label>
                            <input
                                type="text"
                                className="form-control"
                                id="pin"
                                name="pin"
                                value={formData.pin}
                                onChange={handleChange}
                                required
                            />
                        </div>
                        <div className="submitButton">
                            <button type="submit" className="btn btn-primary">Submit</button>
                        </div>
                        <span>Please enter pin given by the Quiz Master</span>
                        <div className="helpInfo">
                            <button onClick={() => openModal('howToPlay')}>
                                <span>How to Play</span>
                            </button>
                        </div>
                    </form>
                </div>
            )}
            {<Modal isOpen={isModalOpen} onClose={closeModal} type={modalType} />}
        </div>
    );
};

export default CheckScores;
