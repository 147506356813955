import React, { useState, useEffect } from "react";
import "./Schedule.css";
import "../../fonts.css";
import { toast } from 'react-toastify';

const Schedule = () => {
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchUpcomingEvents();
  }, []);

  const fetchUpcomingEvents = async () => {
    try {
      // Using the /events/upcoming endpoint
      const response = await fetch(`${process.env.REACT_APP_API_URL}/events/upcoming`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json'
        },
      });

      if (!response.ok) {
        const errorText = await response.text();
        console.error('Server response:', errorText);
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const allEvents = await response.json();
      console.log('Fetched events:', allEvents);

      const processedEvents = allEvents
        .filter(event => event.venue) // Filter out events without venues
        .map(event => ({
          ...event,
          calculatedDate: event.type === 'recurring' && !event.date ? 
            calculateNextOccurrence(event.name) : 
            new Date(event.date)
        }))
        .sort((a, b) => {
          const dateA = a.calculatedDate;
          const dateB = b.calculatedDate;
          return dateA - dateB;
        })
        .slice(0, 5); // Show next 5 events

      console.log('Processed events:', processedEvents);
      setEvents(processedEvents);
    } catch (error) {
      console.error('Error fetching events:', error);
      toast.error('Unable to load upcoming events');
    } finally {
      setLoading(false);
    }
  };

  // Helper function to calculate next occurrence for recurring events
  const calculateNextOccurrence = (eventName) => {
    const today = new Date();
    const days = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];
    const matchingDay = days.find(day => eventName.toLowerCase().includes(day));
    
    if (!matchingDay) {
      console.log('No matching day found in event name:', eventName);
      return today; // Return today if no day found
    }
    
    const todayDay = today.getDay();
    const eventDay = days.indexOf(matchingDay);
    let daysUntilEvent = eventDay - todayDay;
    if (daysUntilEvent <= 0) daysUntilEvent += 7;
    
    const nextDate = new Date(today);
    nextDate.setDate(today.getDate() + daysUntilEvent);
    nextDate.setHours(19, 30, 0, 0); // Default to 7:30 PM
    
    return nextDate;
  };

  const formatDateTime = (date) => {
    if (!(date instanceof Date)) {
      console.error('Invalid date:', date);
      return { day: 'Invalid', date: 'Invalid', time: 'Invalid' };
    }

    return {
      day: date.toLocaleDateString("en-US", {
        weekday: "long",
        timeZone: "America/Denver",
      }),
      date: date.toLocaleDateString("en-US", {
        month: "long",
        day: "numeric",
        timeZone: "America/Denver",
      }),
      time: date.toLocaleTimeString("en-US", {
        hour: "numeric",
        minute: "numeric",
        hour12: true,
        timeZone: "America/Denver",
      })
    };
  };

  if (loading) {
    return (
      <div className="upcoming-dates-container">
        <h2 className="title">Upcoming Dates</h2>
        <div className="loading">Loading events...</div>
      </div>
    );
  }

  if (events.length === 0) {
    return (
      <div className="upcoming-dates-container">
        <h2 className="title">Upcoming Dates</h2>
        <div className="no-events">No upcoming events scheduled</div>
      </div>
    );
  }

  return (
    <div className="upcoming-dates-container">
      <h2 className="title">Upcoming Dates</h2>
      <ul className="dates-list">
        {events.map((event) => {
          const { day, date, time } = formatDateTime(event.calculatedDate);
          
          return (
            <li key={event.id} className="date-item">
              <a 
                href={event.venue.link || '#'} 
                target="_blank" 
                rel="noreferrer"
                className={!event.venue.link ? 'no-link' : ''}
              >
                <div className="business-info">
                  {event.venue.logo && (
                    <img 
                      src={event.venue.logo} 
                      alt={`${event.venue.name} Logo`} 
                      className="logo"
                      onError={(e) => {
                        console.log('Logo failed to load:', event.venue.logo);
                        e.target.style.display = 'none';
                      }}
                    />
                  )}
                  <span className="business-name">
                    {event.venue.name || 'Unknown Venue'}
                  </span>
                </div>
              </a>

              <div className="date-item-labels">
                <div className="day">{day}</div>
                <div className="rightHand">
                  <div className="date">{date}</div>
                  <div className="time">{time}</div>
                </div>
              </div>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

Schedule.displayName = "Schedule";

export default Schedule;
