import React, { forwardRef, useState } from "react";
import Modal from "../Modals/Modal/modal.js";
import "./WorkWithUs.css";

const WorkWithUs = forwardRef((props, ref) => {
  const [activeSection, setActiveSection] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalType, setModalType] = useState('');

  const toggleSection = (section) => {
    setActiveSection(activeSection === section ? null : section);
  };

  const openBookingModal = (type) => {
    setModalType(type);
    setIsModalOpen(true);
  };

  const closeModal = () => setIsModalOpen(false);

  return (
      <div ref={ref} className="work-with-us">
        <div className="work-text-container">
          <h1>Book Us</h1>

          <div className="section">
            <h2 className="section-header" onClick={() => toggleSection(1)}>
              Get Trivia at Your Venue
              <span className={`arrow ${activeSection === 1 ? 'open' : ''}`}>▼</span>
            </h2>
            <div className={`section-content ${activeSection === 1 ? "open" : ""}`}>
            <span>
              Bring the energy of Brain Brew Trivia
              to your venue! Hosting a weekly trivia night not only fills seats but creates
              a loyal community of regulars who return for the thrill of friendly competition.
            </span>
              <button className="book-button" onClick={() => openBookingModal('venueBooking')}>
                Book Now
              </button>
            </div>
          </div>

          <div className="section">
            <h2 className="section-header" onClick={() => toggleSection(2)}>
              Get Trivia at Your Event
              <span className={`arrow ${activeSection === 2 ? 'open' : ''}`}>▼</span>
            </h2>
            <div className={`section-content ${activeSection === 2 ? "open" : ""}`}>
            <span>
              Looking for a unique and engaging way to celebrate? Brain Brew Trivia offers
              one-off trivia events perfect for birthdays, corporate team-building, fundraisers,
              and club gatherings.
            </span>
              <button className="book-button" onClick={() => openBookingModal('eventBooking')}>
                Book Now
              </button>
            </div>
          </div>
        </div>

        <Modal isOpen={isModalOpen} onClose={closeModal} type={modalType} />
      </div>
  );
});

WorkWithUs.displayName = "WorkWithUs";

export default WorkWithUs;