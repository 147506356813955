import React, { useEffect, useState, useMemo } from 'react';
import config from '../../config';
import './AdminLogin.css';
import logo from "../../assets/trivia_logo.svg";
import Scorecards from "../Scorecards/Scorecards";
import PresentationCreator from "../PresentationCreator/PresentationCreator";
import EventEditor from "../EventEditor/EventEditor";
import VenueEditor from "../VenueManager/VenueManager";

const AdminLogin = () => {
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [token, setToken] = useState(null);
    const [isLoggedOut, setIsLoggedOut] = useState(false);
    const [activeTab, setActiveTab] = useState('scorecards');

    const renderComponent = useMemo(() => {
        switch (activeTab) {
            case 'scorecards':
                return <Scorecards token={token} />;
            case 'docToSlides':
                return <PresentationCreator token={token} />;
            case 'eventEditor':
                return <EventEditor />;
            case 'venue':
                return <VenueEditor />;
            default:
                return <Scorecards token={token} />;
        }
    }, [activeTab, token]);


    useEffect(() => {
        const checkTokenExpiry = () => {
            const storedToken = localStorage.getItem('googleOAuthToken');
            const storedExpiry = localStorage.getItem('expiry');

            if (storedToken && storedExpiry && !isLoggedOut) {
                const isTokenExpired = Number(storedExpiry) < Date.now();
                if (isTokenExpired) {
                    localStorage.removeItem('googleOAuthToken');
                    localStorage.removeItem('expiry');
                    setToken(null);
                    setIsLoggedIn(false);
                } else {
                    setToken(storedToken);
                    setIsLoggedIn(true);
                }
            } else if (!isLoggedOut) {
                const hash = window.location.hash;
                if (hash) {
                    const params = new URLSearchParams(hash.substring(1));
                    const newToken = params.get('access_token');
                    const expiresIn = params.get('expires_in');
                    if (newToken && expiresIn) {
                        localStorage.setItem('googleOAuthToken', newToken);
                        const expiryTime = Date.now() + Number(expiresIn) * 1000;
                        localStorage.setItem('expiry', expiryTime.toString());
                        setToken(newToken);
                        setIsLoggedIn(true);
                        window.history.replaceState(null, '', window.location.pathname);
                    }
                } else {
                    setIsLoggedIn(false);
                }
            }
        };

        checkTokenExpiry();
        const intervalId = setInterval(checkTokenExpiry, 3600000); // Check every hour

        return () => clearInterval(intervalId);
    }, [isLoggedOut]);

    const handleLogin = () => {
        const { googleClientId, redirectUri } = config;
        const scope = 'profile email https://www.googleapis.com/auth/spreadsheets.readonly https://www.googleapis.com/auth/presentations https://www.googleapis.com/auth/drive';
        const responseType = 'token';
        const authUrl = `https://accounts.google.com/o/oauth2/v2/auth?client_id=${googleClientId}&redirect_uri=${redirectUri}&scope=${scope}&response_type=${responseType}`;
        window.location.href = authUrl;
    };

    const handleLogout = () => {
        localStorage.removeItem('googleOAuthToken');
        localStorage.removeItem('expiry');
        setToken(null);
        setIsLoggedIn(false);
        setIsLoggedOut(true);
    };

    return (
        <div className="loginPage">
            {isLoggedIn ? (
                <div>
                    <div className="tabContainer">
                        <div onClick={() => setActiveTab('scorecards')}
                             className={`tab ${activeTab === 'scorecards' ? 'active' : ''}`}>
                            Scorecards
                        </div>
                        <div onClick={() => setActiveTab('docToSlides')}
                             className={`tab ${activeTab === 'docToSlides' ? 'active' : ''}`}>
                            Docs to Slides
                        </div>
                        <div onClick={() => setActiveTab('eventEditor')}
                             className={`tab ${activeTab === 'eventEditor' ? 'active' : ''}`}>
                            Events
                        </div>
                        <div onClick={() => setActiveTab('venue')}
                             className={`tab ${activeTab === 'venue' ? 'active' : ''}`}>
                            Venue
                        </div>
                        <div style={{color: "brown", height: '10px'}} />
                    </div>

                    {/*<div onClick={() => setActiveTab('musicBingoCreator')}>Music Bingo Creator</div>*/}

                    <div className='adminPane'>
                        {renderComponent}
                    </div>
                    <div className="logOutButtonContainer">
                        <button className="logOutButton" onClick={handleLogout}>Logout</button>
                    </div>
                </div>
            ) : (
                <div className="buttonContainer">
                    <img className="header_logo" src={logo} alt="Logo" />
                    <button onClick={handleLogin}>Login with Google</button>
                    <h1>How to Log In</h1>
                    <ol>
                        <li>Click the Login with Google button</li>
                        <li>Sign in to your @beergeektrivia Google account</li>
                        <li>Click Allow to grant access</li>
                        <li>You should be directed back to this page</li>
                    </ol>
                </div>
            )}
        </div>
    );
};

export default React.memo(AdminLogin);

