import React from "react";
import PropTypes from 'prop-types';
import Header from "../Header/Header";
import Social from "../Social/Social";
import "./MainLayout.css";

const MainLayout = ({ children }) => (
    <div className="mainLayout">
        <Header />
        <main>
            {children}
        </main>
        <Social />
    </div>
);

MainLayout.propTypes = {
    children: PropTypes.node.isRequired,
};

export default MainLayout;