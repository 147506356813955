import React, { useState, useEffect } from 'react';
import styles from './VenueManager.module.css';
import VenueForm from '../VenueForm/VenueForm';
import { toast } from 'react-toastify';

function VenueManager() {
  const [venues, setVenues] = useState([]);
  const [editingVenue, setEditingVenue] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchVenues();
  }, []);

  const fetchVenues = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/venues/venues`);
      if (!response.ok) throw new Error('Failed to fetch venues');
      const data = await response.json();
      setVenues(data);
    } catch (error) {
      console.error('Error fetching venues:', error);
      toast.error('Failed to load venues');
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async (venueId) => {
    if (!window.confirm('Are you sure you want to delete this venue?')) return;

    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/venues/${venueId}`, {
        method: 'DELETE',
      });

      if (!response.ok) throw new Error('Failed to delete venue');
      
      toast.success('Venue deleted successfully');
      fetchVenues();
    } catch (error) {
      console.error('Error deleting venue:', error);
      toast.error('Failed to delete venue');
    }
  };

  return (
    <div className={styles.venueManagerContainer}>
      <div className={styles.splitView}>
        <div className={styles.listSection}>
          <h2 className={styles.sectionTitle}>Venues</h2>
          {loading ? (
            <div className={styles.loading}>Loading venues...</div>
          ) : venues.length === 0 ? (
            <div className={styles.noVenues}>No venues added yet</div>
          ) : (
            <div className={styles.venueList}>
              {venues.map(venue => (
                <div key={venue.id} className={styles.venueCard}>
                  <div className={styles.venueInfo}>
                    {venue.logo && (
                      <img 
                        src={venue.logo} 
                        alt={`${venue.name} logo`} 
                        className={styles.venueLogo}
                      />
                    )}
                    <div className={styles.venueDetails}>
                      <h3 className={styles.venueName}>{venue.name}</h3>
                      {venue.link && (
                        <a 
                          href={venue.link} 
                          target="_blank" 
                          rel="noopener noreferrer" 
                          className={styles.venueLink}
                        >
                          Visit Website
                        </a>
                      )}
                    </div>
                  </div>
                  <div className={styles.venueActions}>
                    <button
                      onClick={() => setEditingVenue(venue)}
                      className={styles.editButton}
                    >
                      Edit
                    </button>
                    <button
                      onClick={() => handleDelete(venue.id)}
                      className={styles.deleteButton}
                    >
                      Delete
                    </button>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
        
        <div className={styles.formSection}>
          <h2 className={styles.sectionTitle}>
            {editingVenue ? 'Edit Venue' : 'Add New Venue'}
          </h2>
          <VenueForm 
            initialData={editingVenue}
            onSubmitSuccess={() => {
              fetchVenues();
              setEditingVenue(null);
            }}
            onCancel={() => setEditingVenue(null)}
          />
        </div>
      </div>
    </div>
  );
}

export default VenueManager;
