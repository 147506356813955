// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[1]!../../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[2]!../../colors.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mainLayout {
    display: flex;
    flex-direction: column;
    min-height: 100vh; /* Ensure the layout takes up the full view height */
    margin-bottom: 50px;
}

main {
    flex: 1 1; /* Allow the main content to expand */
}
`, "",{"version":3,"sources":["webpack://./src/components/MainLayout/MainLayout.css"],"names":[],"mappings":"AACA;IACI,aAAa;IACb,sBAAsB;IACtB,iBAAiB,EAAE,oDAAoD;IACvE,mBAAmB;AACvB;;AAEA;IACI,SAAO,EAAE,qCAAqC;AAClD","sourcesContent":["@import url('../../colors.css');\n.mainLayout {\n    display: flex;\n    flex-direction: column;\n    min-height: 100vh; /* Ensure the layout takes up the full view height */\n    margin-bottom: 50px;\n}\n\nmain {\n    flex: 1; /* Allow the main content to expand */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
