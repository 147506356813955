import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styles from './VenueForm.module.css';
import { toast } from 'react-toastify';

function VenueForm({ initialData, onSubmitSuccess, onCancel }) {
    const [formData, setFormData] = useState({
        title: '', // Changed from 'name' to 'title' to match API
        logo: null,
        logoPreview: null,
        url: '', // Changed from 'link' to 'url' to match API
    });
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');

    useEffect(() => {
        if (initialData) {
            setFormData({
                title: initialData.name || '', // Map from API's 'name' to form's 'title'
                logo: null,
                logoPreview: initialData.logo || null,
                url: initialData.link || '', // Map from API's 'link' to form's 'url'
            });
        }
    }, [initialData]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData(prev => ({
            ...prev,
            [name]: value
        }));
    };

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            if (file.size > 5 * 1024 * 1024) { // Changed to 5MB to match server limit
                toast.error('File size must be less than 5MB');
                return;
            }
            if (!file.type.startsWith('image/')) {
                toast.error('Only image files are allowed');
                return;
            }

            const previewUrl = URL.createObjectURL(file);
            setFormData(prev => ({
                ...prev,
                logo: file,
                logoPreview: previewUrl
            }));
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        setError('');

        try {
            const submitData = new FormData();
            submitData.append('title', formData.title);
            submitData.append('url', formData.url);
            if (formData.logo) {
                submitData.append('logo', formData.logo);
            }

            const url = initialData 
                ? `${process.env.REACT_APP_API_URL}/venues/${initialData.id}`
                : `${process.env.REACT_APP_API_URL}/venues/venues`; // Added '/venues' to match API route
            
            const response = await fetch(url, {
                method: initialData ? 'PUT' : 'POST',
                body: submitData,
            });

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.error || 'Operation failed');
            }

            const result = await response.json();
            console.log('API Response:', result); // Debug log

            toast.success(`Venue ${initialData ? 'updated' : 'added'} successfully!`);
            if (onSubmitSuccess) onSubmitSuccess();
            resetForm();
        } catch (error) {
            console.error('Error:', error);
            setError(error.message);
            toast.error(error.message);
        } finally {
            setLoading(false);
        }
    };

    const resetForm = () => {
        if (formData.logoPreview && !initialData?.logo) {
            URL.revokeObjectURL(formData.logoPreview);
        }
        setFormData({
            title: '',
            logo: null,
            logoPreview: null,
            url: '',
        });
        setError('');
    };

    useEffect(() => {
        return () => {
            if (formData.logoPreview && !initialData?.logo) {
                URL.revokeObjectURL(formData.logoPreview);
            }
        };
    }, [formData.logoPreview, initialData]);

    return (
        <div className={styles.formContainer}>
            <form onSubmit={handleSubmit} className={styles.form}>
                <div className={styles.inputGroup}>
                    <label htmlFor="title">Venue Name:</label>
                    <input
                        id="title"
                        name="title"
                        type="text"
                        value={formData.title}
                        onChange={handleInputChange}
                        required
                        className={styles.input}
                    />
                </div>

                <div className={styles.inputGroup}>
                    <label htmlFor="logo">Logo:</label>
                    <input
                        id="logo"
                        type="file"
                        accept="image/*"
                        onChange={handleFileChange}
                        className={styles.fileInput}
                    />
                    <small className={styles.helperText}>Maximum file size: 5MB</small>
                    
                    {formData.logoPreview && (
                        <div className={styles.previewContainer}>
                            <img 
                                src={formData.logoPreview} 
                                alt="Logo preview" 
                                className={styles.preview}
                            />
                            <button 
                                type="button" 
                                onClick={() => {
                                    setFormData(prev => ({
                                        ...prev,
                                        logo: null,
                                        logoPreview: null
                                    }));
                                }}
                                className={styles.removeButton}
                            >
                                Remove Image
                            </button>
                        </div>
                    )}
                </div>

                <div className={styles.inputGroup}>
                    <label htmlFor="url">Website URL:</label>
                    <input
                        id="url"
                        name="url"
                        type="url"
                        value={formData.url}
                        onChange={handleInputChange}
                        required
                        className={styles.input}
                        placeholder="https://example.com"
                    />
                </div>

                {error && <div className={styles.error}>{error}</div>}
                
                <div className={styles.buttonGroup}>
                    <button 
                        type="submit" 
                        disabled={loading}
                        className={styles.submitButton}
                    >
                        {loading ? 'Saving...' : initialData ? 'Update Venue' : 'Add Venue'}
                    </button>
                    
                    {initialData && (
                        <button 
                            type="button" 
                            onClick={() => {
                                resetForm();
                                if (onCancel) onCancel();
                            }}
                            className={styles.cancelButton}
                        >
                            Cancel
                        </button>
                    )}
                </div>
            </form>
        </div>
    );
}

// Define PropTypes
VenueForm.propTypes = {
    initialData: PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
        logo: PropTypes.string,
        link: PropTypes.string
    }),
    onSubmitSuccess: PropTypes.func,
    onCancel: PropTypes.func
};

// Define default props
VenueForm.defaultProps = {
    initialData: null,
    onSubmitSuccess: () => {},
    onCancel: () => {}
};

export default VenueForm;
