import React, { useEffect, useState, useMemo } from 'react';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import './Scorecards.css';

const fetchVenues = async () => {
    try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/venues/venues`);
        if (!response.ok) throw new Error('Failed to fetch venues');
        const data = await response.json();
        return Array.isArray(data) ? data : [];
    } catch (error) {
        console.error('Error fetching venues:', error);
        toast.error('Failed to load venues');
        return [];
    }
};

const VenueSelect = ({ value, onChange, venues, disabled, placeholder }) => (
    <select 
        onChange={(e) => onChange(e.target.value)} 
        value={value}
        className="select"
        disabled={disabled}
    >
        <option value="">{placeholder || 'Select Venue'}</option>
        {venues.map((venue) => (
            <option key={venue.id} value={venue.name}>
                {venue.name}
            </option>
        ))}
    </select>
);

const TableHeader = ({ onSort, sortConfig }) => {
    const getSortIndicator = (key) => {
        if (sortConfig.key !== key) return '↕️';
        return sortConfig.direction === 'ascending' ? '↑' : '↓';
    };

    return (
        <thead>
            <tr>
                <th onClick={() => onSort('venue')} className="sortable">
                    Venue {getSortIndicator('venue')}
                </th>
                <th onClick={() => onSort('createdAt')} className="sortable">
                    Created At {getSortIndicator('createdAt')}
                </th>
                <th onClick={() => onSort('modifiedAt')} className="sortable">
                    Modified At {getSortIndicator('modifiedAt')}
                </th>
                <th>Pin</th>
                <th>URL</th>
                <th>Actions</th>
            </tr>
        </thead>
    );
};

const GameRow = ({ game, onUpdate, onDelete, loading }) => (
    <tr>
        <td>{game.venue.name}</td>
        <td>{new Date(game.createdAt).toLocaleString()}</td>
        <td>{new Date(game.modifiedAt).toLocaleString()}</td>
        <td>{game.pin}</td>
        <td>
            <button
                onClick={() => window.open(game.url, '_blank')}
                className="button link"
            >
                Open Sheet
            </button>
        </td>
        <td>
            <div className="button-group">
                <button
                    onClick={() => onUpdate(game.id)}
                    disabled={loading}
                    className="button update"
                    title="Update Game"
                >
                    ↻
                </button>
                <button
                    onClick={() => onDelete(game.id)}
                    disabled={loading}
                    className="button delete"
                    title="Delete Game"
                >
                    🗑️
                </button>
            </div>
        </td>
    </tr>
);

const Scorecards = ({ token }) => {
    const [sheetUrl, setSheetUrl] = useState('');
    const [games, setGames] = useState([]);
    const [venues, setVenues] = useState([]);
    const [venue, setVenue] = useState('');
    const [filter, setFilter] = useState('');
    const [sortConfig, setSortConfig] = useState({ key: null, direction: 'ascending' });
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (token) handleGetAllGames();
    }, [token]);

    useEffect(() => {
        const loadVenues = async () => {
            const data = await fetchVenues();
            setVenues(data);
        };
        loadVenues();
    }, []);

    const handleGetAllGames = async () => {
        setLoading(true);
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/games/get_all_games`, {
                headers: { 'Content-Type': 'application/json' },
            });
            if (!response.ok) throw new Error('Failed to get all games');
            const data = await response.json();
            setGames(data);
            toast.success('Games loaded successfully');
        } catch (error) {
            console.error('Error getting games:', error);
            toast.error('Failed to load games');
        } finally {
            setLoading(false);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!sheetUrl || !venue) {
            toast.error('Please fill in all fields');
            return;
        }

        setLoading(true);
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/games/create_game`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ url: sheetUrl, venueName: venue }),
            });
            if (!response.ok) throw new Error('Failed to create game');
            const data = await response.json();
            console.log(data);
            setGames(data.games);
            setSheetUrl('');
            setVenue('');
            toast.success('Game created successfully');
        } catch (error) {
            console.error('Error creating game:', error);
            toast.error('Failed to create game');
        } finally {
            setLoading(false);
        }
    };

    const handleUpdate = async (gameId) => {
    setLoading(true);
    try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/games/update_game`, {
            method: 'PATCH',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ game_id: gameId, token }),
        });
        if (!response.ok) throw new Error('Failed to update game');

        const updatedGame = await response.json();
        console.log(updatedGame);
        // Update the specific game in the 'games' array
        setGames((prevGames) =>
            prevGames.map((game) => (game.id === updatedGame.id ? updatedGame : game))
        );

        toast.success('Game updated successfully');
    } catch (error) {
        console.error('Error updating game:', error);
        toast.error('Failed to update game');
    } finally {
        setLoading(false);
    }
};

    const handleDelete = async (gameId) => {
        if (!window.confirm('Are you sure you want to delete this game?')) return;
        
        setLoading(true);
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/games/delete_game`, {
                method: 'DELETE',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ game_id: gameId }),
            });
            if (!response.ok) throw new Error('Failed to delete game');
            const data = await response.json();
            console.log('delete data', data);
            setGames(data);
            toast.success('Game deleted successfully');
        } catch (error) {
            console.error('Error deleting game:', error);
            toast.error('Failed to delete game');
        } finally {
            setLoading(false);
        }
    };

    const handleSort = (key) => {
        setSortConfig(prev => ({
            key,
            direction: prev.key === key && prev.direction === 'ascending' ? 'descending' : 'ascending'
        }));
    };

    const sortedAndFilteredGames = useMemo(() => {
        let filteredGames = games || [];
        if (filter) {
            filteredGames = filteredGames.filter(game => game?.venue === filter);
        }
        if (sortConfig.key) {
            filteredGames.sort((a, b) => {
                const aVal = a[sortConfig.key];
                const bVal = b[sortConfig.key];
                const direction = sortConfig.direction === 'ascending' ? 1 : -1;
                return aVal < bVal ? -direction : aVal > bVal ? direction : 0;
            });
        }
        return filteredGames;
    }, [games, filter, sortConfig]);

    return (
        <div className="scorecards-container">
            <div className="input-form">
                <h1>Add Scorecard</h1>
                <form onSubmit={handleSubmit} className="form-content">
                    <div className="form-group">
                        <input
                            type="text"
                            placeholder="Enter Google Sheets URL"
                            value={sheetUrl}
                            onChange={(e) => setSheetUrl(e.target.value)}
                            className="input"
                            disabled={loading}
                        />
                        <VenueSelect
                            value={venue}
                            onChange={setVenue}
                            venues={venues}
                            disabled={loading}
                        />
                        <button 
                            type="submit" 
                            className="button primary"
                            disabled={loading}
                        >
                            {loading ? 'Creating...' : 'Create Game'}
                        </button>
                    </div>
                </form>
            </div>

            <div className="filter-section">
                <VenueSelect
                    value={filter}
                    onChange={setFilter}
                    venues={venues}
                    placeholder="All Venues"
                />
            </div>

            <div className="table-container">
                <table className="games-table">
                    <TableHeader onSort={handleSort} sortConfig={sortConfig} />
                    <tbody>
                        {sortedAndFilteredGames.map((game) => {
                            console.log(game)

                                return (<GameRow
                                key={game.id}
                                game={game}
                                onUpdate={handleUpdate}
                                onDelete={handleDelete}
                                loading={loading}
                            />)
                        })}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

VenueSelect.propTypes = {
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    venues: PropTypes.array.isRequired,
    disabled: PropTypes.bool,
    placeholder: PropTypes.string,
};

TableHeader.propTypes = {
    onSort: PropTypes.func.isRequired,
    sortConfig: PropTypes.object.isRequired,
};

GameRow.propTypes = {
    game: PropTypes.object.isRequired,
    onUpdate: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
};

Scorecards.propTypes = {
    token: PropTypes.string.isRequired,
};

export default React.memo(Scorecards);

