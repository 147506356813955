// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.inputForm {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 20px;
    padding: 20px;
    border-radius: 8px;
    background-color: #f5f5f5;
}

.inputForm form {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
    max-width: 500px;
}

.input, .select {
    padding: 8px;
    border: 1px solid #ddd;
    border-radius: 4px;
    width: 100%;
    font-size: 16px;
}

.button {
    padding: 10px;
    background-color: #4CAF50;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
}

.button:hover {
    background-color: #45a049;
}

table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
}

th, td {
    padding: 12px;
    text-align: left;
    border-bottom: 1px solid #ddd;
}

th {
    background-color: #f4f4f4;
    cursor: pointer;
}

th:hover {
    background-color: #e0e0e0;
}

tr:nth-child(even) {
    background-color: #f9f9f9;
}

tr:hover {
    background-color: #f5f5f5;
}

button {
    padding: 6px 12px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin: 0 4px;
}
`, "",{"version":3,"sources":["webpack://./src/components/EventEditor/EventEditor.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,YAAY;IACZ,aAAa;IACb,kBAAkB;IAClB,yBAAyB;AAC7B;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,SAAS;IACT,WAAW;IACX,gBAAgB;AACpB;;AAEA;IACI,YAAY;IACZ,sBAAsB;IACtB,kBAAkB;IAClB,WAAW;IACX,eAAe;AACnB;;AAEA;IACI,aAAa;IACb,yBAAyB;IACzB,YAAY;IACZ,YAAY;IACZ,kBAAkB;IAClB,eAAe;IACf,eAAe;AACnB;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,WAAW;IACX,yBAAyB;IACzB,gBAAgB;AACpB;;AAEA;IACI,aAAa;IACb,gBAAgB;IAChB,6BAA6B;AACjC;;AAEA;IACI,yBAAyB;IACzB,eAAe;AACnB;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,iBAAiB;IACjB,YAAY;IACZ,kBAAkB;IAClB,eAAe;IACf,aAAa;AACjB","sourcesContent":[".inputForm {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    margin: 20px;\n    padding: 20px;\n    border-radius: 8px;\n    background-color: #f5f5f5;\n}\n\n.inputForm form {\n    display: flex;\n    flex-direction: column;\n    gap: 10px;\n    width: 100%;\n    max-width: 500px;\n}\n\n.input, .select {\n    padding: 8px;\n    border: 1px solid #ddd;\n    border-radius: 4px;\n    width: 100%;\n    font-size: 16px;\n}\n\n.button {\n    padding: 10px;\n    background-color: #4CAF50;\n    color: white;\n    border: none;\n    border-radius: 4px;\n    cursor: pointer;\n    font-size: 16px;\n}\n\n.button:hover {\n    background-color: #45a049;\n}\n\ntable {\n    width: 100%;\n    border-collapse: collapse;\n    margin-top: 20px;\n}\n\nth, td {\n    padding: 12px;\n    text-align: left;\n    border-bottom: 1px solid #ddd;\n}\n\nth {\n    background-color: #f4f4f4;\n    cursor: pointer;\n}\n\nth:hover {\n    background-color: #e0e0e0;\n}\n\ntr:nth-child(even) {\n    background-color: #f9f9f9;\n}\n\ntr:hover {\n    background-color: #f5f5f5;\n}\n\nbutton {\n    padding: 6px 12px;\n    border: none;\n    border-radius: 4px;\n    cursor: pointer;\n    margin: 0 4px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
