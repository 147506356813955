import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import './EventEditor.css';

const EventEditor = () => {
  const [events, setEvents] = useState([]);
  const [venues, setVenues] = useState([]);
  const [formData, setFormData] = useState({
    name: '',
    venueId: '',
    type: 'trivia',
    eventType: 'one-time',
    date: '',
    time: '19:30',
    recurringDay: 'monday',
  });
  const [filter, setFilter] = useState('');
  const [sortConfig, setSortConfig] = useState({ key: null, direction: 'ascending' });

  useEffect(() => {
    fetchVenues();
    fetchEvents();
  }, []);

  const fetchVenues = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/venues/venues`);
      if (!response.ok) throw new Error('Failed to fetch venues');
      const data = await response.json();
      setVenues(data);
    } catch (error) {
      console.error('Error fetching venues:', error);
      toast.error('Error fetching venues');
    }
  };

  const fetchEvents = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/events`);
      if (!response.ok) throw new Error('Failed to fetch events');
      const data = await response.json();
      setEvents(data);
    } catch (error) {
      console.error('Error fetching events:', error);
      toast.error('Error fetching events');
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    try {
      let eventData = {
        name: formData.name,
        venue_id: parseInt(formData.venueId),
        type: formData.type,
      };

      if (formData.eventType === 'one-time') {
        const dateTime = new Date(`${formData.date}T${formData.time}`);
        eventData.date = dateTime.toISOString();
      } else {
        // For recurring events, store the day in the name
        eventData.name = `${formData.name} (Every ${formData.recurringDay.charAt(0).toUpperCase() + formData.recurringDay.slice(1)})`;
      }

      const response = await fetch(`${process.env.REACT_APP_API_URL}/events`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(eventData),
      });

      if (!response.ok) throw new Error('Failed to create event');

      toast.success('Event created successfully');
      fetchEvents(); // Refresh events list
      
      // Reset form
      setFormData({
        name: '',
        venueId: '',
        type: 'trivia',
        eventType: 'one-time',
        date: '',
        time: '19:30',
        recurringDay: 'monday',
      });
    } catch (error) {
      console.error('Error creating event:', error);
      toast.error('Error creating event');
    }
  };

  const handleDelete = async (eventId) => {
    if (!window.confirm('Are you sure you want to delete this event?')) return;
    
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/events/${eventId}`, {
        method: 'DELETE',
      });

      if (!response.ok) throw new Error('Failed to delete event');

      toast.success('Event deleted successfully');
      fetchEvents(); // Refresh events list
    } catch (error) {
      console.error('Error deleting event:', error);
      toast.error('Error deleting event');
    }
  };

  const handleSort = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  const sortedAndFilteredEvents = React.useMemo(() => {
    let filteredEvents = events;

    if (filter) {
      filteredEvents = filteredEvents.filter((event) => event.venue.id === parseInt(filter));
    }

    if (sortConfig.key) {
      filteredEvents.sort((a, b) => {
        if (sortConfig.key === 'venue') {
          return sortConfig.direction === 'ascending' 
            ? a.venue.name.localeCompare(b.venue.name)
            : b.venue.name.localeCompare(a.venue.name);
        }
        
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? 1 : -1;
        }
        return 0;
      });
    }

    return filteredEvents;
  }, [events, filter, sortConfig]);

  return (
    <div>
      <div className="inputForm">
        <h1>Add Event</h1>
        <form onSubmit={handleSubmit}>
          <input
            type="text"
            name="name"
            placeholder="Event Name"
            value={formData.name}
            onChange={handleInputChange}
            className="input"
            required
          />
          
          <select 
            name="venueId" 
            value={formData.venueId}
            onChange={handleInputChange}
            className="select"
            required
          >
            <option value="">Select Venue</option>
            {venues.map(venue => (
              <option key={venue.id} value={venue.id}>
                {venue.name}
              </option>
            ))}
          </select>

          <select
            name="eventType"
            value={formData.eventType}
            onChange={handleInputChange}
            className="select"
          >
            <option value="one-time">One-time Event</option>
            <option value="recurring">Recurring Event</option>
          </select>

          {formData.eventType === 'one-time' ? (
            <>
              <input
                type="date"
                name="date"
                value={formData.date}
                onChange={handleInputChange}
                className="input"
                required
              />
              <input
                type="time"
                name="time"
                value={formData.time}
                onChange={handleInputChange}
                className="input"
                required
              />
            </>
          ) : (
            <select
              name="recurringDay"
              value={formData.recurringDay}
              onChange={handleInputChange}
              className="select"
            >
              {['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'].map(day => (
                <option key={day} value={day}>
                  {day.charAt(0).toUpperCase() + day.slice(1)}
                </option>
              ))}
            </select>
          )}

          <button type="submit" className="button">Create Event</button>
        </form>
      </div>

      <div>
        <h5>Filter by Venue</h5>
        <select onChange={(e) => setFilter(e.target.value)} className="select">
          <option value="">All Venues</option>
          {venues.map(venue => (
            <option key={venue.id} value={venue.id}>
              {venue.name}
            </option>
          ))}
        </select>
      </div>

      <div>
        <table>
          <thead>
            <tr>
              <th onClick={() => handleSort('name')}>
                Event Name {sortConfig.key === 'name' ? sortConfig.direction === "ascending" ? "⬆️" : "⬇️" : ""}
              </th>
              <th onClick={() => handleSort('venue')}>
                Venue {sortConfig.key === 'venue' ? sortConfig.direction === "ascending" ? "⬆️" : "⬇️" : ""}
              </th>
              <th onClick={() => handleSort('date')}>
                Date {sortConfig.key === 'date' ? sortConfig.direction === "ascending" ? "⬆️" : "⬇️" : ""}
              </th>
              <th onClick={() => handleSort('type')}>
                Type {sortConfig.key === 'type' ? sortConfig.direction === "ascending" ? "⬆️" : "⬇️" : ""}
              </th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {sortedAndFilteredEvents.map((event) => (
              <tr key={event.id}>
                <td>{event.name}</td>
                <td>{event.venue.name}</td>
                <td>
                  {event.date ? new Date(event.date).toLocaleDateString('en-US', {
                    weekday: 'long',
                    year: 'numeric',
                    month: 'long',
                    day: 'numeric',
                    hour: '2-digit',
                    minute: '2-digit'
                  }) : 'Recurring Event'}
                </td>
                <td>{event.type}</td>
                <td>
                  <button 
                    style={{backgroundColor: "rgb(250,0,0,.7)"}}
                    onClick={() => handleDelete(event.id)}
                  >
                    🗑️
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default EventEditor;
