import React from 'react';
import './howToPlayModal.css';

const HowToPlayModal = () => {
    return (
        <div className="how-to-play-content">
            <h3>Quiz Rules</h3>
            <ol>
                <li>Dont cheat <p>(if you have to ask if its cheating it is)</p></li>
                <li>Dont shout out answers</li>
            </ol>
            <h3>How to Play</h3>
            <ol>
                <li>Get your answer sheet booklet from the quiz master with a pencil</li>
                <li>Fill out the second sheet with your team name & turn in</li>
                <li>Listen for Round 1 to start!</li>
                <p>In the meantime, work on the visual round - you&apos;ll turn it in with round 4</p>
            </ol>
            <h3>Quiz Format</h3>
            <ol>
                <li>6 Rounds <p>you can double one round per quiz</p></li>
                <li>5 questions per round <p>(8 for visual)</p></li>
                <li>The questions will be read twice the first time</li>
                <li>All questions are repeated at the end of the round</li>
                <li>
                    After all questions are repeated, listen for the round&apos;s song <p>(usually relates to the round or an answer)</p>
                </li>
                <li>Write your answers on the sheet for that round</li>
                <li>Mark if you&apos;re doubling the round (remember: only once per quiz!)</li>
                <li>Tear off your answer sheet for that round</li>
                <li>Turn in the round</li>
            </ol>
        </div>
    );
};

export default HowToPlayModal;