import React, { useState } from 'react';
import '../Modal/modal.css';
import {toast} from "react-toastify";

const AdBookingModal = () => {
  const [formData, setFormData] = useState({ businessName: '', contactEmail: '', adMessage: '' });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const apiUrl = `${process.env.REACT_APP_API_URL}/email/send-email`;
    const emailContent = `Ad Message: ${formData.adMessage}`;
    const emailData = {
      name: formData.businessName,
      fromAddress: formData.contactEmail,
      emailContent,
      type: 'Ad Booking',
    };

    try {
      const response = await fetch(apiUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(emailData),
      });

      if (!response.ok) {
        console.error('Error sending email:', response.statusText);
        toast.error('There was an error sending your request.');
        return;
      }

      toast.success('Booking request sent successfully!');
    } catch (error) {
      console.error('Error sending booking request:', error);
      toast.error('There was an error sending your request.');
    }
  };

  return (
    <div className="modal-content-container">
      <h2>Advertise with Us</h2>
      <p>Reach a local, engaged audience by placing your ad with Brain Brew Trivia. Fill out the form below to learn more!</p>
      <form className="modal-form" onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="businessName" className="inputLabel">Business Name</label>
          <input
            type="text"
            id="businessName"
            name="businessName"
            placeholder="Enter your business name"
            className="form-control"
            value={formData.businessName}
            onChange={handleChange}
          />
        </div>
        <div className="form-group">
          <label htmlFor="contactEmail" className="inputLabel">Contact Email</label>
          <input
            type="email"
            id="contactEmail"
            name="contactEmail"
            placeholder="Enter your contact email"
            className="form-control"
            value={formData.contactEmail}
            onChange={handleChange}
          />
        </div>
        <div className="form-group">
          <label htmlFor="adMessage" className="inputLabel">Ad Message/Details</label>
          <span>This is just a summary; we will reach out with our template for our space and other details.</span>
          <textarea
            id="adMessage"
            name="adMessage"
            placeholder="What would you like to advertise?"
            className="form-control"
            value={formData.adMessage}
            onChange={handleChange}
          />
        </div>
        <div className="submitButton">
          <button type="submit" className="btn btn-primary">Submit Inquiry</button>
        </div>
      </form>
    </div>
  );
};

export default AdBookingModal;
